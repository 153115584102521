import { supabase } from "../auth/supabaseClient";
import { useState, useEffect } from "react";
import Loading from "../ui_templates/Loading";
import OrderTable from "./OrderTable";
import Nav from "./Nav";
import PhoneNav from "./PhoneNav";
import NewOrder from "./NewOrder";

const Orders = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orderList, setOrderList] = useState("");
  const [showNav, setShowNav] = useState(false)
  const [createOrder, setCreateOrder] = useState(false)

  const toggleNav = () => {
      setShowNav(prevShowNav => !prevShowNav)
      console.log(showNav)
  }
  const getOrders = async () => {
    const { data, error } = await supabase.from("orders").select("*");
    if (error) throw error;
    setOrderList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex w-screen box-border">
          <Nav showNav = {showNav} togglePhoneNav = {toggleNav} />
          <div className="flex flex-col min-w-0 w-full items-stretch">
          <PhoneNav togglePhoneNav = {toggleNav}/>
          {!createOrder? <OrderTable orderList={orderList} setCreateOrder={setCreateOrder} />
        : <NewOrder setCreateOrder={setCreateOrder} /> 
        }
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;
