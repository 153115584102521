// import tableData from "../ui_templates/tableData";

// const { data, error } = await supabase
//   .from('orders')
//   .insert([
//     { some_column: 'someValue', other_column: 'otherValue' },
//   ])

// const orderData = {
//   deal_closer: "",
//   order_date: "",
//   customer_name: "",
//   customer_phone: "",
//   order_source: "",
//   customer_address: "",
//   customer_district: "",
//   shipping_method: "",
//   shipping_time: "",
//   order_status: "",
//   reason_to_cancel: "",
//   product_details: "",
//   quantity: 0,
//   base_cost: 0,
//   discount: 0,
//   price: 0,
//   additional_fee: 0,
//   sub_total: 0,
//   paid_amount: 0,
//   pending_amount: 0,
// };

const NewOrder = ({ setCreateOrder }) => {
  return (
    <div className="space-y-6 pb-6 px-4 sm:p-6 lg:p-8 overflow-y-auto h-screen">
      <h1 className="text-xl font-semibold">Add a new order</h1>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              General Information
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              General information about the order
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="deal_closer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Deal closer
                  </label>
                  <select
                    id="deal_closer"
                    name="deal_closer"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option>Tuyen</option>
                    <option>Duyen</option>
                    <option>Van</option>
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="deal_closer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Order Source
                  </label>
                  <select
                    id="deal_closer"
                    name="deal_closer"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option>Facebook</option>
                    <option>Tiktok</option>
                    <option>Web</option>
                  </select>
                </div>

                <div className="col-span-6 md:col-span-3">
                  <label
                    htmlFor="order_date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Order Date
                  </label>
                  <input
                    type="date"
                    name="order_date"
                    id="order_date"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="shipping_time"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Shipping date & time
                  </label>
                  <input
                    type="datetime-local"
                    name="shipping_time"
                    id="shipping_time"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Order notes
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="order_notes"
                      name="order_notes"
                      rows={3}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Any additional information about the order, additional
                    phone, direction, etc
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Customer Information
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Information about the customer.
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="customer_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="customer_name"
                    id="customer_name"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="customer_phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="customer_phone"
                    id="customer_phone"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="customer_address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Street address
                  </label>
                  <input
                    type="text"
                    name="customer_address"
                    id="customer_address"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="customer_district"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Customer District/Province
                  </label>
                  <select
                    id="customer_district"
                    name="customer_district"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option>Long An</option>
                    <option>Hanoi</option>
                    <option>Quan 3</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Product Information
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Information about the product, pricing, etc.
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form>
              <div className="grid grid-cols-6 gap-6">

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="product_category"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Product Category
                  </label>
                  <select
                    id="product_category"
                    name="product_category"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option>Canvas</option>
                    <option>Son Dau</option>
                    <option>Tini</option>
                    <option>Tham</option>
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="warehouse"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Warehouse location
                  </label>
                  <select
                    id="warehouse"
                    name="warehouse"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option>Di An</option>
                    <option>Hanoi</option>
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="shipping_method"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Shiping method
                  </label>
                  <select
                    id="shipping_method"
                    name="shipping_method"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option>Giao & Treo</option>
                    <option>Giao</option>
                    <option>Viettel Post</option>
                  </select>
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="product_details"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Product Details
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="product_details"
                      name="product_details"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Name and quantity of each product in the order
                  </p>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Price
                  </label>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="base_cost"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Base cost
                  </label>
                  <input
                    type="number"
                    name="base_cost"
                    id="base_cost"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="additional_fee"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Additional fee (VAT)
                  </label>
                  <input
                    type="number"
                    name="additional_fee"
                    id="additional_fee"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="paid_amount"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Paid amount
                  </label>
                  <input
                    type="number"
                    name="paid_amount"
                    id="paid_amount"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="order_status"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Order Status
                  </label>
                  <select
                    id="order_status"
                    name="order_status"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option>Cho xu ly</option>
                    <option>Cancel</option>
                    <option>Done</option>
                  </select>
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="reason_to_cancel"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Reason to cancel
                  </label>
                  <input
                    type="text"
                    name="reason_to_cancel"
                    id="reason_to_cancel"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Product information
            </h3>
            <p className="mt-1 text-sm text-gray-500">Product, price, etc.</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form className="space-y-6" action="#" method="POST">
              <fieldset>
                <legend className="text-base font-medium text-gray-900">
                  By Email
                </legend>
                <div className="mt-4 space-y-4">
                  <div className="flex items-start">
                    <div className="h-5 flex items-center">
                      <input
                        id="comments"
                        name="comments"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-700"
                      >
                        Comments
                      </label>
                      <p className="text-gray-500">
                        Get notified when someones posts a comment on a posting.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="candidates"
                        name="candidates"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="candidates"
                        className="font-medium text-gray-700"
                      >
                        Candidates
                      </label>
                      <p className="text-gray-500">
                        Get notified when a candidate applies for a job.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="offers"
                        name="offers"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="offers"
                        className="font-medium text-gray-700"
                      >
                        Offers
                      </label>
                      <p className="text-gray-500">
                        Get notified when a candidate accepts or rejects an
                        offer.
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <div>
                  <legend className="text-base font-medium text-gray-900">
                    Push Notifications
                  </legend>
                  <p className="text-sm text-gray-500">
                    These are delivered via SMS to your mobile phone.
                  </p>
                </div>
                <div className="mt-4 space-y-4">
                  <div className="flex items-center">
                    <input
                      id="push-everything"
                      name="push-notifications"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor="push-everything"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Everything
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="push-email"
                      name="push-notifications"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor="push-email"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Same as email
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="push-nothing"
                      name="push-notifications"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      htmlFor="push-nothing"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      No push notifications
                    </label>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div> */}

      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => setCreateOrder(false)}
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default NewOrder;
