import { supabase } from "./components/auth/supabaseClient";
import { useEffect, useState } from "react";
import Auth from "./components/auth/Auth";
import Orders from "./components/dashboards/Orders";
import { Routes, Route } from 'react-router-dom'

function App() {
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])
  return (
    <div>
      {!session ? (
        <Auth />
      ) : (
        <Routes>
            <Route path="/*" element={<Orders />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
