import tableData from "../ui_templates/tableData";

const OrderTable = ({ orderList, setCreateOrder }) => {
  console.log(orderList);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mt-2">
        <h1 className="text-xl font-semibold">Orders</h1>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => setCreateOrder(true)}
            className="inline-flex items-center justify-center rounded-lg border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add order
          </button>
        </div>
      </div>

      <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="divide-y divide-gray-300 overflow-y-auto block">
          <thead className="bg-gray-50">
            <tr className="divide-x divide-gray-200">
              {tableData.map((item) => {
                return (
                  <th
                    key={item.name}
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    {item.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {orderList.map((order) => (
              <tr key={order.id} className="divide-x divide-gray-200">
                {tableData.map((item) => {
                  return (
                    <td
                      key={item.name}
                      className="whitespace-nowrap p-4 text-sm text-gray-500"
                    >
                      {order[item.name]}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderTable;
