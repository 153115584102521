const tableData = [
  {
    name: "id",
    title: "Order ID"
  },
  {
    name: "deal_closer",
    title: "Deal Closer"
  },
  {
    name: "order_date",
    title: "Order date"
  },
  {
    name: "customer_name",
    title: "Customer Name"
  },
  {
    name: "customer_phone",
    title: "Customer Phone"
  },
  {
    name: "order_source",
    title: "Order Source"
  },
  {
    name: "customer_address",
    title: "Customer Address"
  },
  {
    name: "customer_district",
    title: "Customer District"
  },
  {
    name: "shipping_method",
    title: "Shipping method"
  },
  {
    name: "shipping_time",
    title: "Shipping time"
  },
  {
    name: "order_status",
    title: "Order status"
  },
  {
    name: "reason_to_cancel",
    title: "Reason to cancel"
  },
  {
    name: "product_details",
    title: "Product details"
  },
  {
    name: "quantity",
    title: "Quantity"
  },
  {
    name: "base_cost",
    title: "Base cost"
  },
  {
    name: "discount",
    title: "Discount"
  },
  {
    name: "price",
    title: "Price"
  },
  {
    name: "additional_fee",
    title: "Additional fee"
  },
  {
    name: "sub_total",
    title: "Subtotal"
  },
  {
    name: "paid_amount",
    title: "Paid amount"
  },
  {
    name: "pending_amount",
    title: "Pending amount"
  },
]

export default tableData